// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
    color: #3498db;
    text-align: center;
    margin-top: 20px;
  }
  
  .doctor-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .doctor-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 20px;
    text-align: center;
  }
  
  .doctor-card img {
    width: 100%;
    border-radius: 50%;
  }
  
  .doctor-info {
    margin-top: 10px;
  }
  
  .btn-connect {
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-connect:hover {
    background-color: #2980b9;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Doctors.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,SAAS;EACX;;EAEA;IACE,uBAAuB;IACvB,mBAAmB;IACnB,wCAAwC;IACxC,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["h1 {\n    color: #3498db;\n    text-align: center;\n    margin-top: 20px;\n  }\n  \n  .doctor-list {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 20px;\n  }\n  \n  .doctor-card {\n    background-color: white;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    width: 250px;\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .doctor-card img {\n    width: 100%;\n    border-radius: 50%;\n  }\n  \n  .doctor-info {\n    margin-top: 10px;\n  }\n  \n  .btn-connect {\n    padding: 10px;\n    background-color: #3498db;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .btn-connect:hover {\n    background-color: #2980b9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
