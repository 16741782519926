// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-page {
    padding: 20px;
    text-align: center;
  }
  
  .users-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .user-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    background-color: #f9f9f9;
    width: 300px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  }
  
  .user-card h3 {
    margin-bottom: 10px;
  }
  
  .user-card p {
    margin: 5px 0;
  }
  
  .vaccination-status p {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .status {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
  }
  
  .status.green {
    background-color: #4caf50;
  }
  
  .status.red {
    background-color: #f44336;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ViewUsers.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,2CAA2C;EAC7C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".users-page {\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .users-container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 20px;\n  }\n  \n  .user-card {\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    padding: 20px;\n    background-color: #f9f9f9;\n    width: 300px;\n    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);\n  }\n  \n  .user-card h3 {\n    margin-bottom: 10px;\n  }\n  \n  .user-card p {\n    margin: 5px 0;\n  }\n  \n  .vaccination-status p {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .status {\n    padding: 5px 10px;\n    border-radius: 5px;\n    color: white;\n  }\n  \n  .status.green {\n    background-color: #4caf50;\n  }\n  \n  .status.red {\n    background-color: #f44336;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
